$cdn: 'https://cdn.millions.co';
@import "styles3/scss/base";

.searchBarWrapper {
  z-index: 4;
}

.searchBarSelect {
  width: 100%;
  max-width: 100%;
}
